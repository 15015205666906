import * as React from 'react';
import { SubPageLayout } from '../components';
import { Col, Container, Row } from 'reactstrap';
import CallTile from '../components/ContactTiles/CallTile';
import LocationTile from '../components/ContactTiles/LocationTile';
import HoursTile from '../components/ContactTiles/HoursTile';
import RoutingNumberTile from '../components/ContactTiles/RoutingNumberTile';
import FacebookTile from '../components/ContactTiles/FacebookTile';
import LostOrStolenCardTile from '../components/ContactTiles/LostOrStolenCardTile';
import EmailTile from '../components/ContactTiles/EmailTile';
import generalData from '../data/general.json';

export const ContactPageInternal = (general: typeof generalData) => {
  return (
    <SubPageLayout imageSrc={general.headerImageSrc} title="Contact">
      <Container className="p-3 py-5">
        <Row>
          <Col className="child-spacing-y-4" md={6}>
            <CallTile />
            <LostOrStolenCardTile />
            <EmailTile />
            <LocationTile />
          </Col>
          <Col className="child-spacing-y-4" md={6}>
            <HoursTile driveThruHours={general.hours.driveThru} lobbyHours={general.hours.lobby} />
            <RoutingNumberTile />
            <FacebookTile />
          </Col>
        </Row>
      </Container>
    </SubPageLayout>
  );
};

export default () => <ContactPageInternal {...generalData} />;
